<template>
  <div class="page entry talents">
    <Talents />
  </div>
</template>

<script lang="ts" setup>
onErrorCaptured(
  (
    err: unknown,
    instance: ComponentPublicInstance | null,
    info: string,
  ) => {
    console.log('error is ===> ', err);
    console.log('instance is =====> ', instance);
    console.log('info is ====> ', info);
  },
);
</script>

<style lang="scss" scoped>
.page.entry.talents {
  width: 100%;
  display: flex;
}
</style>
