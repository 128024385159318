import { CompanyTalentFilterType } from '~/types/job';

const companyTalentsDefaultFilter: CompanyTalentFilterType = {
  keyword: {
    isActive: false,
    value: '',
  },
  location: {
    isActive: false,
    country: '',
    city: '',
  },
  skills: {
    isActive: false,
    ids: [],
    selected: [],
  },
  modified_date: {
    isActive: false,
    value: 'all',
  },
  stage_status: {
    isActive: false,
    stage: 'all',
    status: 'all',
  },
  companies: {
    isActive: true,
    ids: [],
    selected: [],
    all: true,
  },
};

export { companyTalentsDefaultFilter };
