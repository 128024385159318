<template>
  <div class="entry component talents">
    <div class="header">
      <div
        v-if="isAssociateJob"
        class="associate-job"
        :class="{ selected: selectedCount > 0 }"
      >
        <template v-if="selectedCount > 0">
          <Loader v-if="isAssociating" class="loader" size="small" />
          <template v-else>
            <span class="text">
              <span class="count">{{ selectedCount }}</span>
              Talent{{ selectedCount > 1 ? 's' : '' }}
              selected
            </span>
            <button class="associate" @click.prevent="associateToJob">
              <icon
                class="icon"
                data="@/assets/icons/briefcase.svg?icon"
              />
              <span class="text">Associate Now</span>
            </button>
            <button class="clear" @click.prevent="clearSelected">
              clear
            </button>
          </template>
        </template>
        <template v-else>
          <button
            class="back"
            title="Go Back"
            @click.prevent="$router.go(-1)"
          >
            <icon
              class="icon"
              data="@/assets/icons/caret-left.svg?icon"
            />
          </button>
          <span class="text">
            Associates Talent(s) for {{ associateJobTitle }}
          </span>
        </template>

        <button class="filter" @click="showFilter = !showFilter">
          <icon class="icon" data="@/assets/icons/filter.svg?icon" />
          <span class="text">Filter</span>
          <icon
            class="icon"
            data="@/assets/icons/caret-down.svg?icon"
          />
        </button>
      </div>
      <div v-else-if="selectedCount > 0" class="selected">
        <span class="text">
          <span class="count">{{ selectedCount }}</span>
          Talent{{ selectedCount > 1 ? 's' : '' }}
          selected
        </span>
        <button
          class="associate"
          @click.prevent="showAssociateTalentsToJob = true"
        >
          <icon
            class="icon"
            data="@/assets/icons/briefcase.svg?icon"
          />
          <span class="text">Associate Job Openings</span>
        </button>
        <button class="message">
          <icon class="icon" data="@/assets/icons/message.svg?icon" />
          <span class="text">Send Message</span>
        </button>
        <button class="add-label">
          <icon class="icon" data="@/assets/icons/tag.svg?icon" />
          <span class="text">Add Label</span>
        </button>
        <button class="clear" @click.prevent="clearSelected">
          clear
        </button>
      </div>
      <template v-else>
        <div class="title">Talents</div>
        <div class="table-header">
          <nuxt-link
            v-if="canCreateTalent"
            class="new"
            :to="{ name: 'company-talent-create' }"
          >
            <icon class="icon" data="@/assets/icons/user.svg?icon" />
            <span class="text">create Talent</span>
          </nuxt-link>
          <!--
          <button v-if="canCreateTalent" class="import">
            <span class="text">Import Talent</span>
          </button>
          -->

          <button
            v-tooltip="`Toggle columns chooser`"
            class="stack"
            @click="toggleColumnsHiddenTray"
          >
            <icon class="icon" data="@/assets/icons/stack.svg?icon" />
          </button>
          <span class="search-input-group">
            <span class="input-icon">
              <input
                v-model="tableSearchValue"
                type="text"
                name="search"
                placeholder="Search Talents"
                @keyup.enter="handleSearchTalents"
              />

              <icon
                class="icon"
                data="@/assets/icons/microscope.svg?icon"
                @click.prevent="handleSearchTalents"
              />
            </span>
          </span>
          <button class="filter" @click="showFilter = !showFilter">
            <icon
              class="icon"
              data="@/assets/icons/filter.svg?icon"
            />
            <span class="text">Filter</span>
            <icon
              class="icon"
              data="@/assets/icons/caret-down.svg?icon"
            />
          </button>
        </div>
      </template>
    </div>
    <DxDataGrid
      ref="tableRef"
      class="data-table"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :auto-navigate-to-focused-row="true"
      :column-auto-width="true"
      :column-width="'auto'"
      column-resizing-mode="widget"
      :hover-state-enabled="true"
      no-data-text="Ooops.. You dont have any associated talent"
      :repaint-changes-only="true"
      :selected-row-keys="selectedRows"
      :on-selection-changed="updateSelectedRows"
      :data-source="dataSource"
      :remote-operations="true"
      :two-way-binding-enabled="false"
      width="100%"
    >
      <DxGroupPanel :visible="true" />
      <DxSorting mode="multiple" />
      <DxColumnChooser
        :enabled="false"
        mode="select"
        sort-order="asc"
      >
        <DxColumnChooserSearch
          :editor-options="{
            placeholder: 'Search columns',
            mode: 'search',
          }"
          :enabled="true"
          :timeout="500"
        />
      </DxColumnChooser>
      <DxColumnFixing :enabled="true" />
      <DxFilterRow :visible="false" apply-filter="auto" />
      <DxFilterPanel :visible="false" />
      <DxHeaderFilter :visible="false" />
      <DxSearchPanel
        :visible="false"
        :width="240"
        placeholder="Search..."
      />

      <DxSelection
        v-if="canEditTalent"
        select-all-mode="page"
        show-check-boxes-mode="always"
        mode="multiple"
      />

      <DxScrolling
        mode="standard"
        :use-native="false"
        :preload-enabled="false"
        row-rendering-mode="standard"
        column-rendering-mode="standard"
        show-scrollbar="onHover"
      />

      <DxPager
        :visible="true"
        :show-page-size-selector="true"
        :show-navigation-buttons="true"
        :show-info="true"
        info-text="Page {0} of {1} ({2} Talents)"
        display-mode="adaptive"
      />
      <DxPaging :enabled="true" :page-size="20" />
      <DxLoadPanel
        :enabled="true"
        :shading="true"
        :show-panel="true"
        :show-indicator="true"
        shading-color="rgba(240, 240, 240, 0.9)"
        text="Loading talents..."
      />
      <DxToolbar :disabled="true" :visible="false" />

      <DxColumn
        v-if="canEditTalent"
        alignment="center"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="false"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="false"
        :allow-hiding="false"
        :allow-reordering="false"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="false"
        :auto-expand-group="false"
        css-class="column-selector"
        :render-async="false"
        type="selection"
        :visible="true"
        width="50px"
      />

      <DxColumn
        name="rating"
        data-field="rating"
        data-type="number"
        caption="Rating"
        cell-template="ratingTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="false"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="rating-column"
        :render-async="false"
        :visible="true"
        width="80px"
      />

      <template #ratingTemplate="{ data }">
        <span class="column--rating--wrapper">
          <span class="text">{{ round(data.value, 2) }}</span>
          <icon class="icon" data="@/assets/icons/star.svg?icon" />
        </span>
      </template>

      <DxColumn
        name="uniqueId"
        data-field="uniqueId"
        data-type="string"
        caption="Talent ID"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-id-column"
        :render-async="false"
        :visible="true"
        width="130px"
      />

      <DxColumn
        name="name"
        data-field="name"
        data-type="string"
        caption="Talent Name"
        cell-template="talentNameTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-name-column"
        :render-async="false"
        :visible="true"
        width="220px"
      />

      <template #talentNameTemplate="{ data }">
        <button
          v-tooltip="'View Talent'"
          class="column--talent-name--wrapper"
          @click="viewTalent(data)"
        >
          <span class="image">
            <img
              :src="extractTalentProfilePicture(data)"
              alt=""
              class="profile-picture"
            />
          </span>
          <span class="text">
            {{ capitalizeString(data.value, 'Goodtalent') }}
          </span>
          <icon class="icon" data="@/assets/icons/eye.svg?icon" />
        </button>
      </template>

      <DxColumn
        name="location"
        data-field="location"
        data-type="string"
        caption="Talent Location"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-location-column"
        :render-async="false"
        :visible="true"
        width="250px"
      />

      <DxColumn
        name="role"
        data-field="role"
        data-type="string"
        caption="Talent Role"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-role-column"
        :render-async="false"
        :visible="true"
        width="250px"
      />

      <DxColumn
        name="email"
        data-field="email"
        data-type="string"
        caption="Email Address"
        cell-template="talentEmailTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-email-column"
        :render-async="false"
        :visible="true"
        width="250px"
      />

      <template #talentEmailTemplate="{ data }">
        <a
          v-tooltip="'Send Email Message'"
          :href="`mailto:${data.value}`"
          class="column--talent-email--wrapper"
        >
          {{ data.value }}
        </a>
      </template>

      <DxColumn
        name="phoneNumber"
        data-field="phoneNumber"
        data-type="string"
        caption="Phone Number"
        cell-template="talentPhoneNumberTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-phone-number-column"
        :render-async="false"
        :visible="true"
        width="140px"
      />

      <template #talentPhoneNumberTemplate="{ data }">
        <a
          v-tooltip="'Call Phone Number'"
          :href="`tel::${data.value}`"
          class="column--talent-phone-number--wrapper"
        >
          {{ data.value }}
        </a>
      </template>

      <DxColumn
        name="gender"
        data-field="gender"
        data-type="string"
        caption="Gender"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-gender-column"
        :render-async="false"
        :visible="true"
        width="80px"
      />

      <DxColumn
        name="status"
        data-field="status"
        data-type="string"
        caption="Status"
        cell-template="statusTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="status-column"
        :render-async="false"
        :visible="true"
        width="200px"
      />

      <template #statusTemplate="{ data }">
        <span
          v-if="data.value"
          class="column--status--wrapper"
          :class="[lowerCaseString(data.value, '')]"
        >
          <span>
            {{ capitalizeString(data.value, '') }}
          </span>
        </span>
      </template>

      <DxColumn
        name="owner"
        data-field="owner"
        data-type="string"
        caption="Talent Owner"
        cell-template="talentOwnerTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-owner-column"
        :render-async="false"
        :visible="true"
        width="200px"
      />

      <template #talentOwnerTemplate="{ data }">
        <span class="column--talent-owner--wrapper">
          <span class="image">
            <img
              :src="extractTalentOwnerProfilePicture(data)"
              alt=""
              class="profile-picture"
            />
          </span>
          <span class="text">
            {{ capitalizeString(data.value?.name, 'Goodtalent') }}
          </span>
        </span>
      </template>

      <DxColumn
        name="createdDate"
        data-field="createdDate"
        data-type="date"
        caption="Created Date"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-created-date-column"
        :render-async="false"
        :visible="true"
        width="190px"
      >
        <DxFormat type="dd/MM/yyyy, hh:mm a" />
      </DxColumn>

      <DxColumn
        name="modifiedDate"
        data-field="modifiedDate"
        data-type="date"
        caption="Modified Date"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="talent-modified-date-column"
        :render-async="false"
        :visible="true"
        width="190px"
      >
        <DxFormat type="dd/MM/yyyy, hh:mm a" />
      </DxColumn>

      <DxColumn
        name="availability"
        data-field="availability"
        data-type="object"
        caption="Talent Availability"
        cell-template="talentAvailabilityTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="false"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="false"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="false"
        :auto-expand-group="false"
        css-class="talent-availability-column"
        :render-async="false"
        :visible="true"
        width="400px"
      />

      <template #talentAvailabilityTemplate="{ data }">
        <span class="column--talent-availability--wrapper">
          <span
            v-for="availability in (data.value || []).slice(0, 2)"
            :key="availability"
            class="availability"
          >
            {{ capitalizeString(availability) }}
          </span>
          <span
            v-if="data.value && data.value.length > 2"
            class="remaining-count"
            :title="data.value.slice(2).join(', ')"
          >
            +{{ data.value.length - 2 }}
          </span>
        </span>
      </template>

      <DxColumn
        name="interests"
        data-field="interests"
        data-type="object"
        caption="Interested In"
        cell-template="interestedInTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="false"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="false"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="false"
        :auto-expand-group="false"
        css-class="interested-in-column"
        :render-async="false"
        :visible="true"
        width="400px"
      />

      <template #interestedInTemplate="{ data }">
        <span class="column--interested-in--wrapper">
          <span
            v-for="interested in (data.value || []).slice(0, 2)"
            :key="interested"
            class="interested"
          >
            {{ capitalizeString(interested) }}
          </span>
          <span
            v-if="data.value && data.value.length > 2"
            class="remaining-count"
            :title="data.value.slice(2).join(', ')"
          >
            +{{ data.value.length - 2 }}
          </span>
        </span>
      </template>

      <DxColumn
        name="hourlyRate"
        data-field="hourlyRate"
        data-type="number"
        caption="Hourly Rate ($)"
        :format="{
          currencyDisplay: 'narrowSymbol',
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }"
        :customize-text="
          (val: Record<string, unknown>) => val.valueText
        "
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="hourly-rate-column"
        :render-async="false"
        :visible="true"
        width="120px"
      />

      <DxColumn
        name="monthlyRate"
        data-field="monthlyRate"
        data-type="number"
        caption="Monthly Rate ($)"
        :format="{
          currencyDisplay: 'narrowSymbol',
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }"
        :customize-text="
          (val: Record<string, unknown>) => val.valueText
        "
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="monthly-rate-column"
        :render-async="false"
        :visible="true"
        width="120px"
      />

      <DxColumn
        name="annualRate"
        data-field="annualRate"
        data-type="number"
        caption="Annual Rate ($)"
        :format="{
          currencyDisplay: 'narrowSymbol',
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }"
        :customize-text="
          (val: Record<string, unknown>) => val.valueText
        "
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="annual-rate-column"
        :render-async="false"
        :visible="true"
        width="120px"
      />

      <DxColumn
        name="maxHoursAvailableWeekly"
        data-field="maxHoursAvailableWeekly"
        data-type="number"
        caption="Max Hrs Available/week"
        :format="(value: number | string) => `${value} hours`"
        :customize-text="
          (val: Record<string, unknown>) => val.valueText
        "
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="annual-rate-column"
        :render-async="false"
        :visible="true"
        width="120px"
      />

      <DxColumn
        name="skills"
        data-field="skills"
        data-type="object"
        caption="Skills"
        cell-template="skillsTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="false"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="false"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="false"
        :auto-expand-group="false"
        css-class="skills-column"
        :render-async="false"
        :visible="true"
        width="250px"
      />

      <template #skillsTemplate="{ data }">
        <span class="column--skills--wrapper">
          <span
            v-for="skill in (data.value || []).slice(0, 2)"
            :key="skill"
            class="skill"
          >
            {{ capitalizeString(skill) }}
          </span>
          <span
            v-if="data.value && data.value.length > 2"
            class="remaining-count"
            :title="data.value.slice(2).join(', ')"
            @click="viewTalent(data, 'talent-summary')"
          >
            +{{ data.value.length - 2 }}
          </span>
        </span>
      </template>

      <DxColumn
        name="verifiedSkills"
        data-field="verifiedSkills"
        data-type="object"
        caption="Verified Skills"
        cell-template="verifiedSkillsTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="false"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="false"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="false"
        :auto-expand-group="false"
        css-class="verified-skills-column"
        :render-async="false"
        :visible="true"
        width="250px"
      />

      <template #verifiedSkillsTemplate="{ data }">
        <span class="column--verified-skills--wrapper">
          <span
            v-for="skill in (data.value || []).slice(0, 2)"
            :key="skill"
            class="skill"
          >
            {{ capitalizeString(skill) }}
          </span>
          <span
            v-if="data.value && data.value.length > 2"
            class="remaining-count"
            :title="data.value.slice(2).join(', ')"
            @click="viewTalent(data, 'talent-summary')"
          >
            +{{ data.value.length - 2 }}
          </span>
        </span>
      </template>

      <DxColumn
        name="hasVideoIntroduction"
        data-field="hasVideoIntroduction"
        data-type="boolean"
        caption="Video Intro"
        cell-template="videoIntroTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="true"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="video-intro-column"
        :render-async="false"
        :visible="true"
        width="100px"
      />

      <template #videoIntroTemplate="{ data }">
        <span class="column--video-intro--wrapper">
          <template v-if="!data.value">No</template>
          <button
            v-else
            @click="viewTalent(data, 'pre-recorded-video')"
          >
            Yes
          </button>
        </span>
      </template>

      <DxColumn
        name="hasAboutTalent"
        data-field="hasAboutTalent"
        data-type="boolean"
        caption="About Talent"
        cell-template="aboutTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="true"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="about-column"
        :render-async="false"
        :visible="true"
        width="100px"
      />

      <template #aboutTemplate="{ data }">
        <span class="column--about--wrapper">
          <template v-if="!data.value">No</template>
          <button v-else @click="viewTalent(data, 'talent-summary')">
            Yes
          </button>
        </span>
      </template>

      <DxColumn
        name="hasResume"
        data-field="hasResume"
        data-type="boolean"
        caption="Resume"
        cell-template="resumeTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="true"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="resume-column"
        :render-async="false"
        :visible="true"
        width="100px"
      />

      <template #resumeTemplate="{ data }">
        <span class="column--resume--wrapper">
          <template v-if="!data.value">No</template>
          <button v-else @click="viewTalent(data, 'resume')">
            Yes
            <icon
              class="icon"
              data="@/assets/icons/file-text.svg?icon"
            />
          </button>
        </span>
      </template>

      <DxColumn
        name="hasWorkHistory"
        data-field="hasWorkHistory"
        data-type="boolean"
        caption="Work History"
        cell-template="workHistoryTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="true"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="work-history-column"
        :render-async="false"
        :visible="true"
        width="100px"
      />

      <template #workHistoryTemplate="{ data }">
        <span class="column--work-history--wrapper">
          <template v-if="!data.value">No</template>
          <button v-else @click="viewTalent(data, 'work-history')">
            Yes
          </button>
        </span>
      </template>

      <DxColumn
        name="links"
        data-field="links"
        data-type="object"
        caption="Links"
        cell-template="linksTemplate"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="true"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="false"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="false"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="links-column"
        :render-async="false"
        :visible="true"
        width="200px"
      />

      <template #linksTemplate="{ data }">
        <span class="column--links--wrapper">
          <a
            v-for="link in prepareLinks(data.value)"
            :key="link.name"
            class="link"
            :href="link.link"
            target="_blank"
            :title="`${link.name} Profile`"
          >
            <icon class="icon" :data="link.icon" original />
          </a>
        </span>
      </template>

      <DxColumn
        name="source"
        data-field="source"
        data-type="string"
        caption="Source"
        alignment="left"
        :allow-editing="false"
        :allow-exporting="false"
        :allow-filtering="true"
        :allow-fixing="true"
        :allow-grouping="true"
        :allow-header-filtering="true"
        :allow-hiding="true"
        :allow-reordering="true"
        :allow-resizing="false"
        :allow-search="true"
        :allow-sorting="true"
        :auto-expand-group="false"
        css-class="source-column"
        :render-async="false"
        :visible="true"
        width="200px"
      />
    </DxDataGrid>

    <OverlaysTalentPreview
      v-if="shownTalentData && shownTalentData.id"
      :id="shownTalentData.id"
      :is-application="shownTalentData.isApplication"
      :show-tab="shownTalentData.tab"
      :data="shownTalentData.data"
      @close="closeTalentPreview"
      @refresh="refreshList"
      @show-prev="showTalent(false)"
      @show-next="showTalent(true)"
    />

    <OverlaysCompanyTalentsFilter
      v-if="showFilter"
      :filter="filter"
      @filter="filter = $event"
      @close="showFilter = false"
    />

    <OverlaysCompanyAssociateTalentsToJob
      v-if="showAssociateTalentsToJob"
      :is-associating="isAssociating"
      @associate="handleAssociateTalentsToJob"
      @close="showAssociateTalentsToJob = false"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineAsyncComponent,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxScrolling,
  DxSorting,
  DxGroupPanel,
  DxFilterRow,
  DxFilterPanel,
  DxHeaderFilter,
  DxSearchPanel,
  DxSelection,
  DxFormat,
  DxPager,
  DxPaging,
  DxLoadPanel,
  DxToolbar,
  DxColumnChooserSearch,
} from 'devextreme-vue/data-grid';
import {
  capitalize,
  cloneDeep,
  first,
  get,
  isArray,
  isBoolean,
  isEmpty,
  isObject,
  isString,
  set,
  values,
} from 'lodash-es';
import { parse } from 'qs';
import round from 'locutus/php/math/round';
import DataSource from 'devextreme/data/data_source';
import { useRoute, useRouter } from 'vue-router';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { Row } from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { FetchError } from 'ofetch';
import { DxGridLoadOptionKeys } from '@/utils/helpers';
import {
  isErrorResponse,
  isValidationErrorResponse,
  SocialLink,
} from '@/types/logged';
import { CompanyProfilePermissionType } from '@/types/permission';

import {
  TalentApplicationViewType,
  TalentProfileType,
} from '@/types/talentProfile';
import { FilePurpose } from '@/support/uploader';
import { CompanyTalentFilterType } from '@/types/job';
import { companyTalentsDefaultFilter } from '~/utils/constants';

const OverlaysCompanyTalentsFilter = defineAsyncComponent({
  loader: () =>
    import('@/components/Overlays/CompanyTalentsFilter.vue'),
});

const OverlaysTalentPreview = defineAsyncComponent({
  loader: () => import('@/components/Overlays/TalentPreview.vue'),
});

const OverlaysCompanyAssociateTalentsToJob = defineAsyncComponent({
  loader: () =>
    import('@/components/Overlays/CompanyAssociateTalentsToJob.vue'),
});

type ShownTalentType = {
  id: string;
  rating: number;
  applicationId: string | number | undefined;
  uniqueId: string;
  name: string;
  location: string;
  role: string | undefined | null;
  email: string;
  phoneNumber: string;
  gender: string | null | undefined;
  status: string | undefined;
  createdDate: Date;
  modifiedDate: Date | null;
  availability: string[];
  interests: string[];
  hourlyRate: string | number | null;
  monthlyRate: string | number | null;
  annualRate: string | number | null;
  maxHoursAvailableWeekly: string | number | null;
  skills: string[];
  verifiedSkills: string[];
  hasVideoIntroduction: boolean;
  hasAboutTalent: boolean;
  hasResume: boolean;
  hasWorkHistory: boolean;
  links: SocialLink[];
  source: string;
  profilePictureUrl: string;
  owner: TalentProfileType['owner'];
  rawData: TalentProfileType | TalentApplicationViewType;
};

const toastId = ref('pages-company-talents-list');
const toast = useToast();
const route = useRoute();
const router = useRouter();
const recaptcha = useReCaptcha();
const user = useUser();
await user.isModuleReady();
const associateJobIds = computed(() => {
  let ids = get(route, 'query.ids', []) as string[] | string;
  if (isString(ids)) {
    ids = values(parse(ids)) as unknown as string[];
  }

  if (!isArray(ids)) {
    ids = [];
  }

  return ids;
});

const associateJobTitle = computed(() => {
  let title = get(route, 'query.title', null);

  if (!isString(title) || title.length === 0) {
    title =
      associateJobIds.value.length > 1 ? 'the following jobs' : 'job';
  } else {
    title = title
      .split('-')
      .map((str) => capitalize(str))
      .join(' ');
  }

  return title;
});

const isAssociateJob = computed(
  () =>
    get(route, 'query.action') === 'associate-job' &&
    associateJobIds.value.length,
);

const selectedIds = ref<string[]>([]);
const selectedCount = computed(() => selectedIds.value.length);
const selectedRows = computed({
  get() {
    return cloneDeep(selectedIds.value);
  },
  set(value) {
    return !!value;
  },
});

const showFilter = ref(false);
const filter = ref<CompanyTalentFilterType>(
  companyTalentsDefaultFilter,
);

const dataStore = new CustomStore({
  key: 'id',
  async load(loadOptions: LoadOptions) {
    const searchParams: Record<string, unknown> = {};
    DxGridLoadOptionKeys.forEach(function (key) {
      const value = get(loadOptions, key);
      const isValueValid = isObject(value)
        ? !isEmpty(value)
        : isBoolean(value)
        ? true
        : !!value;

      if (key in loadOptions && isValueValid) {
        set(searchParams, key, value);
      }
    });

    if (
      filter.value.keyword?.isActive &&
      filter.value.keyword.value
    ) {
      searchParams.name = filter.value.keyword.value;
      tableSearchValue.value = String(searchParams.name || '');
    }

    if (filter.value.location?.isActive) {
      if (filter.value.location.city) {
        set(
          searchParams,
          'location.city',
          filter.value.location.city,
        );
      }

      if (filter.value.location.country) {
        set(
          searchParams,
          'location.country_id',
          filter.value.location.country,
        );
      }
    }

    if (
      filter.value.modified_date?.isActive &&
      filter.value.modified_date.value
    ) {
      searchParams.date_modified = filter.value.modified_date.value;
    }

    if (
      filter.value.skills?.isActive &&
      filter.value.skills.ids &&
      filter.value.skills.ids.length > 0
    ) {
      searchParams.skills = filter.value.skills.ids;
    }

    if (filter.value.stage_status?.isActive) {
      if (filter.value.stage_status.stage !== 'all') {
        set(
          searchParams,
          'application.stage',
          filter.value.stage_status.stage,
        );
      }

      if (filter.value.stage_status.status !== 'all') {
        set(
          searchParams,
          'application.stage_status',
          filter.value.stage_status.status,
        );
      }
    }

    searchParams.companies = filter.value.companies?.isActive
      ? filter.value.companies.ids
      : ['all'];

    try {
      const talentRequestResp = await useRequest('talents', {
        params: searchParams,
      });

      if (talentRequestResp.status === 200) {
        const data = talentRequestResp._data;
        const count = get(data, 'body.count', 0) as number;
        let results = get(data, 'body.list', []) as (
          | TalentProfileType
          | TalentApplicationViewType
        )[];

        if (!isArray(results)) {
          results = [];
        }

        const talents = results.map((result) => {
          const isApplication =
            'isApplication' in result && result.isApplication;
          const talent = (
            isApplication ? get(result, 'talent') : result
          ) as TalentProfileType;
          const application = (
            isApplication ? result : null
          ) as TalentApplicationViewType;

          const modifiedDate = talent.updatedAt
            ? new Date(talent.updatedAt)
            : null;

          let locationString = '';

          if (talent.country) {
            locationString = capitalize(talent.country);
          }

          if (talent.city) {
            const city = capitalize(talent.city);

            if (locationString) {
              locationString = `${city}, ${locationString}`;
            } else {
              locationString = city;
            }
          }

          let sourceString = '';
          switch (talent.source) {
            case 'added':
              sourceString = 'Created By Company';
              break;

            case 'associated':
              sourceString = 'Associated To Job';
              break;

            case 'community':
              sourceString = 'Community Connection';
              break;
          }

          const availability: string[] = [];
          if (talent.isActivelySeeking) {
            availability.push('Actively Seeking');
          }

          if (talent.isPassivelySeeking) {
            availability.push('Passively Seeking');
          }

          if (talent.isAvailable) {
            availability.push('Available');
          }

          if (talent.onlyGigs) {
            availability.push('Only Gigs');
          }

          if (talent.onlyProjects) {
            availability.push('Only Projects');
          }

          if (talent.onlyPartTime) {
            availability.push('Only Part time');
          }

          const interests: string[] = [];
          if (talent.interestedInShortTermContract) {
            interests.push('Short term contracts');
          }

          if (talent.interestedInFullTimeSalaried) {
            interests.push('Full-time salaried');
          }

          if (talent.interestedInFreelance) {
            interests.push('Freelance');
          }

          if (talent.interestedInRemote) {
            interests.push('Remote');
          }

          if (talent.interestedInOnSite) {
            interests.push('On-Site');
          }

          return {
            id: talent.id,
            rating: parseFloat(String(application?.rating || '0')),
            applicationId: application?.id || undefined,
            uniqueId: talent.uniqueId,
            name: `${capitalize(talent.firstName)} ${capitalize(
              talent.lastName,
            )}`,
            location: locationString,
            role: talent.headline,
            email: talent.email,
            phoneNumber: `${talent.phoneCode}${talent.phoneNumber}`,
            gender: capitalize(talent.gender || ''),
            status: application?.status || undefined,
            createdDate: new Date(talent.createdAt),
            modifiedDate,
            availability,
            interests,
            hourlyRate: talent.minHourlyRate,
            monthlyRate: talent.minMonthlyRate,
            annualRate: talent.minYearlyRate,
            maxHoursAvailableWeekly: talent.maxAvailableWeeklyHours,
            skills: talent.skills.map((skill) =>
              capitalize(skill.name),
            ),
            verifiedSkills: talent.skills
              .filter((skill) => skill.isVerified)
              .map((skill) => capitalize(skill.name)),
            hasVideoIntroduction: !!talent.files.find(
              (file) =>
                file.purpose ===
                  FilePurpose.TALENT_PROFILE_INTERVIEW_VIDEO ||
                file.purpose ===
                  FilePurpose.JOB_APPLICATION_INTERVIEW_VIDEO,
            ),
            hasAboutTalent: !!talent.biography,
            hasResume: !!(
              application?.resume ||
              talent.files.find(
                (file) =>
                  file.purpose === FilePurpose.TALENT_PROFILE_RESUME,
              )
            ),
            hasWorkHistory: (talent.workHistory || []).length > 0,
            source: sourceString,
            links: talent.links,
            profilePictureUrl:
              talent.files?.find(
                (file) =>
                  file.purpose === FilePurpose.TALENT_PROFILE_PICTURE,
              )?.url || '',
            owner: talent.owner,
            rawData: result,
          };
        });

        return {
          data: talents,
          totalCount: parseInt(String(count), 10) || 0,
        };
      }
    } catch (e) {
      if (get(e, 'name') !== 'AbortError') {
        console.dir(e);
        toast.error(
          'Ooops... an error occurred while loading talents',
        );
      }
    }

    return {
      data: [],
      totalCount: 0,
      summary: 0,
      groupCount: 0,
    };
  },
});

const dataSource = new DataSource({
  store: dataStore,
});

dataSource.sort([
  {
    selector: 'isSelected',
    desc: true,
  },
]);

const updateSelectedRows = (data: Record<string, string[]>) => {
  selectedIds.value = cloneDeep(data.selectedRowKeys);
};

const tableRef = ref<DxDataGrid | undefined>();
const tableInstance = computed(() => tableRef.value?.instance);
const tableSearchValue = ref('');

const refreshList = async () => {
  await dataSource.reload();
};

watch(filter, refreshList, {
  deep: true,
});

const selectedTalentId = ref('');
const shownTalentData = reactive<{
  isApplication: boolean;
  id: string;
  tab: string;
  data?: TalentApplicationViewType | TalentProfileType;
}>({
  isApplication: false,
  id: '',
  tab: '',
});

const showTalent = async function (
  next: boolean | null = null,
  tab = '',
): Promise<void> {
  const dataArray = ((await dataSource.items()) ||
    []) as ShownTalentType[];

  let index = dataArray.findIndex(
    (talent: ShownTalentType) => talent.id === selectedTalentId.value,
  );

  if (index > -1) {
    const total = dataArray.length;

    if (next === true) {
      if (index >= total - 1) {
        toast.error('Ooops.. This is the last talent in the list', {
          id: toastId.value,
          timeout: 5000,
        });

        return;
      } else {
        index = index + 1;
      }
    } else if (next === false) {
      if (index - 1 <= -1) {
        toast.error('Ooops.. This is the first talent in the list', {
          id: toastId.value,
          timeout: 5000,
        });

        return;
      } else {
        index = index - 1;
      }
    }
  } else if (dataArray.length) {
    index = 0;
  } else {
    toast.error('Ooops.. Empty application data', {
      id: toastId.value,
      timeout: 5000,
    });

    return;
  }

  const talentData = get(dataArray, index);
  if (talentData) {
    selectedTalentId.value = talentData.id;
    shownTalentData.tab = tab;

    if (talentData.rawData) {
      shownTalentData.data = talentData.rawData;
    }

    if (talentData.applicationId) {
      shownTalentData.isApplication = true;
      shownTalentData.id = String(talentData.applicationId);
    } else {
      shownTalentData.isApplication = false;
      shownTalentData.id = talentData.id;
    }
  }
};

const closeTalentPreview = () => {
  selectedTalentId.value = '';
  shownTalentData.id = '';
  shownTalentData.tab = '';
  shownTalentData.data = undefined;
  shownTalentData.isApplication = false;
};

const clearSelected = () => (selectedIds.value = []);

const viewTalent = async (data: Row['data'], showTab = '') => {
  const talentId = get(data, 'data.id') as string;
  selectedTalentId.value = talentId;
  await showTalent(null, showTab);
};

const isAssociating = ref(false);
const processTalentsToJobsAssociation = async (
  jobIds: string[],
  talentIds: string[],
  stageStatus = '',
) => {
  if (isAssociating.value) {
    toast.error('Ooops.. Some talents are associating to job(s)', {
      timeout: 4000,
      id: toastId.value,
    });

    return;
  }

  try {
    if (
      !(
        isDevelopment.value ||
        (isProduction.value &&
          recaptcha &&
          (await recaptcha.recaptchaLoaded()))
      )
    ) {
      toast.error(
        'Ooops.. cannot submit request at this time.. Please refresh this page',
        {
          id: toastId.value,
          timeout: 5000,
        },
      );

      return false;
    }

    isAssociating.value = true;
    const token = isProduction.value
      ? await recaptcha?.executeRecaptcha('associate_talents_to_jobs')
      : 'token';

    const response = await useRequest('company-ats-bulk-actions', {
      method: 'post',
      body: {
        action: 'associate-talents-to-jobs',
        jobIds,
        talentIds,
        stageStatus: stageStatus || undefined,
        'g-captcha-key': token,
      },
    });

    isAssociating.value = false;
    if (response.status === 204) {
      await router.go(-1);

      toast.success('Job(s) associated to talent(s) successfully', {
        id: toastId.value,
        timeout: 4000,
      });
    } else {
      toast.error('Please refresh this page...', {
        id: toastId.value,
        timeout: 4000,
      });
    }
  } catch (e: unknown) {
    isAssociating.value = false;
    if (!e) window.location.reload();

    if (e instanceof FetchError) {
      const status = parseInt(
        get(e, 'response.status', '0') as string,
        10,
      );
      if (status === 400) {
        const errResp = e.data;

        if (isValidationErrorResponse(errResp)) {
          if (isArray(get(errResp, 'error.data'))) {
            const error = first(errResp.error.data);
            if (error) {
              const errMsg = get(values(error.constraints), 0);

              return toast.error(errMsg, {
                id: toastId.value,
                timeout: 4000,
              });
            }
          }

          return toast.error(errResp.error.message, {
            id: toastId.value,
            timeout: 4000,
          });
        }

        if (isErrorResponse(errResp)) {
          return toast.error(errResp.error.message, {
            id: toastId.value,
            timeout: 4000,
          });
        }
      }
    } else if (!isOnline.value) {
      return toast.error(
        'Ooops.. An error occured while sending request.. PLease check your internet connection',
        {
          id: toastId.value,
          timeout: 4000,
        },
      );
    }

    return toast.error('An error occurred during association', {
      id: toastId.value,
      timeout: 4000,
    });
  }
};

const associateToJob = () =>
  processTalentsToJobsAssociation(
    associateJobIds.value,
    selectedIds.value,
  );

const showAssociateTalentsToJob = ref(false);
const handleAssociateTalentsToJob = (
  jobIds: string[],
  stageStatus: string,
) =>
  processTalentsToJobsAssociation(
    jobIds,
    selectedIds.value,
    stageStatus,
  );

const canEditTalent = computed(() => {
  return user.hasPermissionTo({
    canEdit: [CompanyProfilePermissionType.TALENT_GENERAL],
  });
});

const canCreateTalent = computed(() => {
  return user.hasPermissionTo({
    canCreate: [CompanyProfilePermissionType.TALENT_GENERAL],
  });
});

// const canDeleteTalent = computed(() => {
//   return user.hasPermissionTo({
//     canDelete: [CompanyProfilePermissionType.TALENT_GENERAL],
//   });
// });

const defaultProfileImage = computedAsync(async () => {
  const imgUrl = await import('@/assets/images/pngs/avatar.png?url');
  return (get(imgUrl, 'default', imgUrl) as string | undefined) || '';
}, '');

const extractTalentProfilePicture = computed(
  () => (data: Row['data']) => {
    return (
      get(data, 'row.data.profilePictureUrl', '') ||
      defaultProfileImage.value
    );
  },
);

const capitalizeString = (str: string, def = ''): string => {
  return capitalize(str || def);
};

const lowerCaseString = (str: string, def = ''): string => {
  return String(str || def).toLowerCase();
};

const prepareLinks = (links: SocialLink[] | undefined) => {
  if (!isArray(links)) {
    return [];
  }

  return links
    .filter((link) => !!link.link)
    .map((link) => {
      const icon = getSocialIcon.value(link.name.toLowerCase());

      return {
        ...link,
        icon,
      };
    });
};

const handleSearchTalents = () => {
  const instance = tableInstance.value;
  const searchString = tableSearchValue.value.trim();

  if (instance) {
    set(filter.value, 'keyword', {
      isActive: true,
      value: searchString,
    });

    instance.searchByText(searchString);
  }
};

const toggleColumnsHiddenTray = () => {
  if (tableInstance.value) {
    tableInstance.value.showColumnChooser();
  }
};

const paperClipImageUrl = computedAsync(async () => {
  const imgUrl = await import(
    '@/assets/images/pngs/paperclip.png?url'
  );
  return (get(imgUrl, 'default', imgUrl) as string | undefined) || '';
}, '');

const extractTalentOwnerProfilePicture = computed(
  () => (data: Row['data']) => {
    const owner = get(
      data,
      'row.data.owner',
    ) as TalentProfileType['owner'];

    if (
      !owner ||
      !owner.isAvailable ||
      (owner.isCompany && !(owner.imageFile && owner.imageFile.url))
    ) {
      return paperClipImageUrl.value;
    }

    return owner.imageFile?.url || defaultProfileImage.value;
  },
);
</script>

<style lang="scss" scoped>
@use 'sass:color' as sasscolor;
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;

.entry.component.talents {
  :deep() {
    display: flex;
    position: relative;
    height: calc(100vh - 60px);
    max-width: viewport.$max-width;
    width: 100%;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    > * {
      padding: 15px 40px 0;
      background-color: color.$white-1;
    }

    > .header {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: {
        left: 20px;
        right: 20px;
      }

      > * {
        display: flex;
        width: 100%;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }

      > .associate-job {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        > .back {
          display: inline-flex;
          width: auto;
          background: initial;
          padding: 0;
          margin: 0;
          border: initial;
          outline: initial;
          cursor: pointer;

          > .icon {
            width: 15px;
            height: 15px;
            color: color.$brown-2;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            > .icon {
              transform: scale(1.4);
            }
          }
        }

        > .text {
          display: inline-flex;
          margin: 0 0 0 20px;
          font-family: 'LatoBold', 'LatoRegular', Tahoma, Geneva,
            sans-serif;
          font-size: 23px;
        }

        > .filter {
          display: inline-flex;
          cursor: pointer;
          outline: initial;
          justify-content: center;
          margin: 0 0 0 auto;
          border-radius: 8px;
          height: 36px;
          align-items: center;
          background-color: color.$white-1;
          border: 1px solid color.$brown-2;
          overflow: hidden;
          padding: 0 10px;
          transition: all 0.3s ease-in-out;

          > .icon {
            width: 18px;
            height: 20px;
            color: color.$brown-2;

            &:first-child {
              margin: 0 10px 0 0;
              transform: scaleY(1.3);
            }

            &:last-child {
              width: 12px;
            }
          }

          > .text {
            height: 100%;
            border-left: 1px solid color.$brown-2;
            padding: 0 10px;
            display: inline-flex;
            align-items: center;
          }

          &:hover {
            box-shadow: 1px 1px 5px 0
              sasscolor.adjust(color.$ash-1, $lightness: +20%);
          }
        }

        > .loader {
          height: 80px;
          min-height: 80px;
          width: 100px;
        }
      }

      > .selected {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;

        > .text {
          display: inline-flex;
          font-size: 20px;
          text-transform: capitalize;
          color: color.$black-1;
          margin-right: auto;

          > .count {
            display: inline-flex;
            margin: 0 15px 0 0;
          }
        }

        > .associate,
        > .message,
        > .add-label {
          display: inline-flex;
          cursor: pointer;
          background-color: color.$white-1;
          border: 1px solid color.$brown-2;
          color: color.$brown-2;
          padding: 8px 10px;
          outline: initial;
          justify-content: center;
          margin: 0;
          border-radius: 8px;
          overflow: hidden;
          height: 36px;
          align-items: center;
          transition: all 0.3s ease-in-out;

          > .icon {
            color: color.$brown-2;
            width: 18px;
            height: 18px;
            transform: scale(1.1);
          }

          > .text {
            margin-left: 10px;
          }

          &:not(:first-child) {
            margin: 0 0 0 30px;
          }

          &:hover {
            box-shadow: 1px 1px 5px 0
              sasscolor.adjust(color.$ash-1, $lightness: +20%);
          }
        }

        > .clear {
          display: inline-flex;
          cursor: pointer;
          background-color: initial;
          outline: initial;
          border: none;
          margin: 0;
          margin-left: 60px;
          margin-right: auto;
          padding: 0;
          text-decoration: underline;
          font-size: 20px;
          color: color.$brown-2;
          text-transform: capitalize;
        }
      }

      > .title {
        display: inline-flex;
        font-size: 24px;
        text-transform: capitalize;
        color: color.$black-1;
        margin-right: auto;
      }

      > .table-header {
        justify-content: flex-start;
        align-items: center;

        > button,
        a {
          display: inline-flex;
          cursor: pointer;
          background-color: color.$white-1;
          outline: initial;
          border: 1px solid #281103;
          color: #281103;
          padding: 0;
          justify-content: center;
          margin: 0;
          border-radius: 8px;
          overflow: hidden;
          height: 36px;
          align-items: center;
          transition: all 0.3s ease-in-out;

          &:not(:first-child) {
            margin-left: 20px;
          }

          &:hover {
            box-shadow: 1px 1px 5px 0
              sasscolor.adjust(color.$ash-1, $lightness: +20%);
          }
        }
        > .new {
          padding: 8px 10px;
          background-color: #281103;
          color: color.$white-1;
          text-transform: capitalize;
          text-decoration: initial;

          > .text {
            margin: 0 0 0 10px;
          }

          > .icon {
            height: 90%;
          }
        }

        > .import {
          padding: 8px 10px;
          margin-right: 15px;
        }

        > .stack {
          padding: 8px;
          margin-left: auto !important;
        }
        > .filter {
          overflow: hidden;
          padding: 0 10px;

          > .icon {
            width: 18px;
            height: 20px;
            color: #281103;

            &:first-child {
              margin: 0 10px 0 0;
              transform: scaleY(1.3);
            }

            &:last-child {
              width: 12px;
            }
          }

          > .text {
            height: 100%;
            border-left: 1px solid #281103;
            padding: 0 10px;
            display: inline-flex;
            align-items: center;
          }
        }

        > .search-input-group {
          margin-left: 20px;

          > .input-icon {
            display: inline-flex;
            min-width: 300px;
            border: none;
            position: relative;
            align-items: center;
            margin: 0;

            @include breakpoint.respond-below(lg) {
              min-width: initial;
            }

            > input {
              width: 100%;
              height: 36px;
              padding: 0 0 0 20px;
              color: color.$ash-1;
              border: 1px solid #281103;
              border-radius: 8px;
              transition: all 0.3s ease-in-out;
              outline: initial;
              background-color: color.$white-1;

              &:focus {
                box-shadow: 1px 1px 5px 0 rgba(color.$ash-1, 0.4);
              }
            }

            > .icon {
              width: 15px;
              height: 15px;
              position: absolute;
              right: 20px;
              color: color.$ash-2;
              transition: all 0.3s ease-in-out;
              cursor: pointer;
            }
          }
        }
      }

      > .status {
        display: inline-flex;
        width: auto;
        min-width: 150px;
        height: 36px;

        > .multiselect-single-label {
          color: color.$brown-2;
        }

        > .multiselect-caret {
          filter: sepia(100%);
        }
      }
    }

    > .data-table {
      padding: {
        right: 0;
        left: 0;
        top: 15px;
      }

      > .dx-datagrid {
        font-size: 14px;
        > .dx-datagrid-header-panel {
          padding: {
            left: 15px;
            right: 15px;
          }
        }

        > .dx-datagrid-headers {
          border: none !important;
          > .dx-datagrid-content {
            > .dx-datagrid-table {
              &.dx-datagrid-table-fixed {
                > colgroup {
                  > col {
                    width: auto !important;
                  }
                }
              }

              > tbody {
                > .dx-header-row {
                  background-color: #281103;
                  color: color.$white-1;
                  height: 53px;

                  &:not(.dx-freespace-row) {
                    &,
                    td,
                    th {
                      border: 1px solid #bdbdbd;
                      border-collapse: collapse;
                    }

                    &:first-child {
                      &,
                      td,
                      th {
                        border-top: none;
                      }
                    }
                  }

                  > td {
                    &.column-selector {
                      padding: 10px;
                      display: table-cell;
                      text-align: center;
                      vertical-align: middle;

                      > .dx-checkbox {
                        > .dx-checkbox-container {
                          > .dx-checkbox-icon {
                            background-color: #281103;
                            border: 1.5px solid color.$white-1;
                            border-radius: 3px;
                          }
                        }
                      }
                    }

                    > .dx-column-indicators {
                      float: initial !important;
                      margin: 0 10px 0 0;

                      &.dx-visibility-hidden {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            &:not(.dx-datagrid-content-fixed),
            &.dx-datagrid-content-fixed {
              > .dx-datagrid-table {
                > tbody {
                  > .dx-header-row {
                    > td {
                      &.column-selector {
                        border: none !important;
                      }
                    }
                  }
                }
              }
            }

            &.dx-datagrid-content-fixed {
              > .dx-datagrid-table {
                > tbody {
                  > .dx-header-row {
                    > td {
                      &.dx-last-cell {
                        border-left: none;
                      }
                    }
                  }
                }
              }
            }

            &:not(.dx-datagrid-content-fixed) {
              > .dx-datagrid-table {
                > tbody {
                  > .dx-header-row {
                    > td {
                      &:nth-child(2) {
                        border-left: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        > .dx-datagrid-rowsview {
          border: none !important;
          > .dx-scrollable-wrapper {
            > .dx-scrollable-container {
              overflow-x: auto;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              &::-webkit-scrollbar {
                display: none;
              }

              > .dx-scrollable-content {
                > .dx-datagrid-content {
                  > .dx-datagrid-table-fixed {
                    > colgroup {
                      > col {
                        width: auto !important;
                      }
                    }
                  }

                  > .dx-datagrid-table {
                    > tbody {
                      > .dx-row {
                        &.dx-freespace-row {
                          &,
                          td,
                          th {
                            border: none;
                            border-right: none;
                            border-left: none;
                            border-top: none;
                            border-bottom: none;
                          }
                        }

                        &:not(.dx-freespace-row) {
                          &,
                          td,
                          th {
                            border: 1px solid #bdbdbd;
                            border-collapse: collapse;
                          }

                          &:first-child {
                            &,
                            td,
                            th {
                              border-top: none;
                            }
                          }
                        }

                        > td {
                          padding-right: 10px;
                          padding-left: 10px;
                          height: 53px;

                          &.column-selector {
                            padding: 10px;
                            display: table-cell;
                            text-align: center;
                            vertical-align: middle;
                            border: none !important;
                          }

                          &:nth-child(2) {
                            border-left: none;
                          }

                          .dx-datagrid-search-text {
                            background-color: color.$brown-2;
                            color: color.$white-1;
                            border-radius: 1px;
                          }

                          &.rating-column {
                            > .column--rating--wrapper {
                              display: inline-flex;
                              align-items: center;
                              justify-content: flex-start;

                              > .icon {
                                width: 15px;
                                height: 15px;
                                transform: scale(1);
                                margin: 0 0 0 10px;
                                color: color.$yellow-1;
                              }
                            }
                          }

                          &.talent-name-column
                            > .column--talent-name--wrapper,
                          &.talent-owner-column
                            > .column--talent-owner--wrapper {
                            display: inline-flex;
                            align-items: center;
                            justify-content: flex-start;
                            outline: initial;
                            padding: 0;
                            margin: 0;
                            border: none;
                            background-color: initial;
                            cursor: pointer;

                            > .image {
                              display: inline-flex;
                              width: 18px;
                              height: 18px;

                              > img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: scale-down;
                              }
                            }

                            > .text {
                              display: inline-flex;
                              margin: 0 0 0 10px;
                              color: #0d47a1;
                            }

                            > .icon {
                              width: 15px;
                              height: 16px;
                              transform: scale(1.1);
                              margin: 0 0 0 10px;
                              color: #757575;
                            }
                          }

                          &.talent-email-column
                            > .column--talent-email--wrapper,
                          &.talent-phone-number-column
                            > .column--talent-phone-number--wrapper {
                            display: inline-flex;
                            align-items: center;
                            justify-content: flex-start;
                            outline: initial;
                            padding: 0;
                            margin: 0;
                            border: none;
                            background-color: initial;
                            cursor: pointer;
                            text-decoration: initial;
                            color: #0d47a1;
                          }

                          &.status-column {
                            > .column--status--wrapper {
                              display: inline-flex;
                              padding: 4px 10px;
                              justify-content: flex-start;
                              align-items: center;
                              background-color: color.$brown-2;
                              color: color.$white-1;
                              border-radius: 12px;
                              transition: all 0.3s ease-in-out;

                              > span {
                                display: inline-flex;
                                align-items: center;

                                &::before {
                                  content: '';
                                  display: inline-flex;
                                  width: 6px;
                                  height: 6px;
                                  border-radius: 50%;
                                  background-color: color.$white-1;
                                  transition: all 0.3s ease-in-out;
                                  margin: 0 10px 0 0;
                                }
                              }
                            }
                          }

                          &.talent-availability-column
                            > .column--talent-availability--wrapper,
                          &.interested-in-column
                            > .column--interested-in--wrapper,
                          &.skills-column > .column--skills--wrapper,
                          &.verified-skills-column
                            > .column--verified-skills--wrapper {
                            display: inline-flex;
                            flex-wrap: nowrap;
                            position: relative;
                            align-items: center;

                            > * {
                              background-color: #bdbdbd;
                              color: color.$black-1;

                              &:not(:first-child) {
                                margin-left: 5px;
                              }
                            }

                            > .availability,
                            > .interested,
                            > .skill {
                              display: inline-flex;
                              padding: 6px 12px;
                              border-radius: 18px;
                            }

                            > .remaining-count {
                              display: inline-flex;
                              align-items: center;
                              justify-content: center;
                              padding: 8px;
                              border-radius: 50%;
                              position: absolute;
                              right: -29px;
                              top: -10px;
                              flex-shrink: 0;
                              width: 25px;
                              height: 25px;
                            }
                          }

                          &.talent-availability-column
                            > .column--talent-availability--wrapper {
                            > * {
                              background-color: #e1f5fe;
                            }
                          }

                          &.interested-in-column
                            > .column--interested-in--wrapper {
                            > * {
                              background-color: #e8eaf6;
                            }
                          }

                          &.skills-column > .column--skills--wrapper {
                            > * {
                              background-color: color.$black-1;
                              color: color.$white-1;
                            }
                          }

                          &.verified-skills-column
                            > .column--verified-skills--wrapper {
                            > * {
                              background-color: #43a047;
                              color: color.$white-1;
                            }
                          }

                          &.video-intro-column
                            > .column--video-intro--wrapper,
                          &.about-column > .column--about--wrapper,
                          &.resume-column > .column--resume--wrapper,
                          &.work-history-column
                            > .column--work-history--wrapper {
                            display: inline-flex;
                            justify-content: flex-start;
                            align-items: center;

                            > button {
                              cursor: pointer;
                              margin: 0;
                              padding: 0;
                              text-decoration: underline;
                              color: #1976d2;
                              background-color: initial;
                              border: initial;
                              display: inline-flex;
                              justify-content: flex-start;
                              align-items: center;

                              > .icon {
                                color: #1976d2;
                                display: inline-flex;
                                margin-left: 4px;
                                width: 18px;
                                height: 18px;
                              }
                            }
                          }

                          &.links-column {
                            vertical-align: middle;

                            > .column--links--wrapper {
                              display: inline-flex;
                              justify-content: flex-start;
                              align-items: center;

                              > * {
                                display: inline-flex;
                                width: 22px;
                                height: 20px;

                                &:not(:first-child) {
                                  margin-left: 5px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          > .dx-datagrid-content {
            > .dx-datagrid-table {
              > colgroup {
                > col {
                  width: auto !important;
                }
              }

              > tbody {
                > tr.dx-row {
                  &.dx-freespace-row {
                    &,
                    td,
                    th {
                      border: none;
                      border-right: none;
                      border-left: none;
                      border-top: none;
                      border-bottom: none;
                    }
                  }

                  &:not(.dx-freespace-row) {
                    &,
                    td,
                    th {
                      border: 1px solid #bdbdbd;
                      border-collapse: collapse;
                    }

                    &:first-child {
                      &,
                      td,
                      th {
                        border-top: none;
                      }
                    }
                  }

                  > td {
                    padding-right: 10px;
                    padding-left: 10px;
                    height: 53px;

                    &.column-selector {
                      padding: 10px;
                      display: table-cell;
                      text-align: center;
                      vertical-align: middle;
                      border: none !important;
                    }

                    &.dx-last-cell {
                      border-left: none;
                    }
                  }
                }
              }
            }
          }

          //Row focused state
          > .dx-scrollable-wrapper
            > .dx-scrollable-container
            > .dx-scrollable-content
            > .dx-datagrid-content
            > .dx-datagrid-table
            > tbody
            > tr.dx-row.dx-row-focused:not(.dx-freespace-row)
            > td,
          > .dx-datagrid-content
            > .dx-datagrid-table
            > tbody
            > tr.dx-row.dx-row-focused:not(.dx-freespace-row)
            > td.column-selector {
            background-color: #f0f0f0;
            color: initial;
          }

          //Row hover state
          > .dx-scrollable-wrapper
            > .dx-scrollable-container
            > .dx-scrollable-content
            > .dx-datagrid-content
            > .dx-datagrid-table
            > tbody
            > tr.dx-row.dx-state-hover:not(.dx-freespace-row)
            > td,
          > .dx-datagrid-content
            > .dx-datagrid-table
            > tbody
            > tr.dx-row.dx-state-hover:not(.dx-freespace-row)
            > td {
            background-color: #f7f7f7;
            color: initial;
          }
        }

        > .dx-datagrid-pager {
          > .dx-page-sizes {
            > .dx-page-size {
              border-radius: 8px;

              &.dx-selection {
                background-color: #281103;
                color: color.$white-1;
              }
            }
          }

          > .dx-pages {
            > .dx-page-indexes {
              > .dx-page {
                border-radius: 8px;

                &.dx-selection {
                  background-color: #281103;
                  color: color.$white-1;
                }
              }
            }
          }
        }

        > .dx-overlay-wrapper.dx-loadpanel-wrapper.dx-overlay-shader {
          width: 100% !important;
        }
      }

      .dx-datagrid-content .dx-datagrid-table .dx-row > td,
      .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
        vertical-align: middle;
      }

      .dx-row.dx-data-row {
        &:hover {
          background-color: sasscolor.adjust(
            color.$white-1,
            $lightness: -3%
          );
        }
      }

      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused)
        > td,
      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused)
        > tr
        > td,
      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused):hover
        > td,
      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused):hover
        > tr
        > td {
        background-color: initial;
        color: initial;
      }
    }
  }
}
</style>
